var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "MESSAGE font_one" }, [
    _vm.messageData.contentType === 99
      ? _c("div", { staticClass: "system font_zero fontWeight_regular" }, [
          _vm._v(" " + _vm._s(_vm.messageData.chatContent) + " "),
        ])
      : _c(
          "div",
          {
            staticClass: "normal",
            class: { isMe: _vm.messageData.isMe, noMe: !_vm.messageData.isMe },
          },
          [
            _c("NewImg", {
              attrs: { src: _vm.messageData.photo, objectFit: null },
            }),
            _c(
              "div",
              {
                staticClass: "messageCont",
                class: { file: _vm.messageData.contentType === 2 },
              },
              [
                _vm.messageData.contentType === 1
                  ? _c("span", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.messageData.chatContent)),
                    ])
                  : _vm._e(),
                _vm.messageData.contentType === 2
                  ? _c("newImg", {
                      staticClass: "image",
                      attrs: {
                        clickImg: true,
                        src: _vm.messageData.chatContent,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }