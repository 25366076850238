<template>
  <div class="MESSAGE font_one">
    <!-- 系统 -->
    <div
      v-if="messageData.contentType === 99"
      class="system font_zero fontWeight_regular"
    >
      {{ messageData.chatContent }}
    </div>
    <!-- 非系统消息 -->
    <div
      v-else
      class="normal"
      :class="{ isMe: messageData.isMe, noMe: !messageData.isMe }"
    >
      <NewImg :src="messageData.photo" :objectFit="null"></NewImg>
      <div class="messageCont" :class="{ file: messageData.contentType === 2 }">
        <!-- text -->
        <span v-if="messageData.contentType === 1" class="text">{{
          messageData.chatContent
        }}</span>
        <!-- image -->
        <newImg
          v-if="messageData.contentType === 2"
          :clickImg="true"
          class="image"
          :src="messageData.chatContent"
        ></newImg>
      </div>
    </div>

    <!-- <newImg v-show="previewImage" class="previewImage" :src="previewImage"></newImg> -->
  </div>
</template>

<script>
export default {
  props: {
    /**
     * type: system text,
     * value: 显示的值
     * isMe: 是否是自己的消息
     * **/
    messageData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    NewImg: () => import("../NewImg"),
  },
  data() {
    return {
      previewImage: "",
    };
  },
  created() {},
};
</script>

<style scoped lang="scss">
::v-deep .image img {
  width: 100%;
}
.MESSAGE {
  width: 100%;
  > div {
    margin-bottom: 20px;
  }
  .system {
    width: 100%;
    text-align: center;
    color: #9e9e9f;
  }
  .normal {
    display: flex;
    > .new_img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      overflow: hidden;
    }
    .messageCont {
      background-color: #fff;
      border-radius: 10px;
      margin: 0 10px;
      position: relative;
      &::before {
        content: " ";
        position: absolute;

        top: 20px;
        transform: translate(0, -50%);
        border-color: transparent;
        border-style: solid;
        border-width: 6px;
      }
      &.file {
        padding: 10px;
      }
      .text {
        display: block;
        margin: 10px;
      }
      .image {
        width: 200px;
        cursor: pointer;
      }
    }
    &.noMe {
      .messageCont {
        &::before {
          left: -6px;
          border-right-color: #e7eaef;
          border-left-width: 0;
          color: #224e96;
        }
      }
    }
    &.isMe {
      flex-direction: row-reverse;
      .messageCont {
        background-color: #4b6388;
        color: #fff;
        &::before {
          right: -6px;
          border-right-width: 0;
          border-left-color: #4b6388;
        }
      }
    }
  }
}
</style>